export interface AuthStateModel {
  access_token: string | null;
  refresh_token: string | null;
}

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { email: string; password: string }) {}
}

export class LoginToken {
  static readonly type = '[Auth] LoginToken';
  constructor(public payload: { token: string }) {}
}

export class RegisterProviderToken {
  static readonly type = '[Auth] ProviderToken';
  constructor(public payload: { provider: string, code: string, phone: string }) {}
}

export class LoginProviderToken {
  static readonly type = '[Auth] LoginProviderToken';
  constructor(public payload: { provider: string, code: string }) {}
}

export class Register {
  static readonly type = '[Auth] Register';
  constructor(public payload: { enterprise: string|null, first_name: string, last_name: string, email: string, phone: string, password: string, profile_type: string, sponsorship_token: string|null, business: number|null}) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class Recover {
  static readonly type = '[Auth] Recover';
  constructor(public payload: { email: string}) {}
}

export class ResetPassword {
  static readonly type = '[Auth] Reset password';
  constructor(public payload: { password: string, passwordConfirm: string, token: string, email: string}) {}
}
