import {AuthStateModel, Login, LoginToken, Logout, Recover, Register, ResetPassword, RegisterProviderToken, LoginProviderToken} from "../shared/models/Auth";
import {AuthService} from "../auth/data-access/auth.service";
import {Action, Selector, State, StateContext, Store} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {tap} from "rxjs";
import {Logout as LogoutSubscriber} from "../shared/models/Subscriber";

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    access_token: null,
    refresh_token: null,
  }
})
@Injectable({
  providedIn: 'root'
})
export class AuthState {
  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.access_token;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string | null {
    return state.refresh_token;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.access_token;
  }

  constructor(private authService: AuthService, private store: Store) {}

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.authService.login(action.payload.email, action.payload.password).pipe(
      tap((result) => {
        ctx.patchState({
          access_token: result.access_token,
          refresh_token: result.access_token
        });
        // this.store.dispatch(new GetUser())
      })
    );
  }

  @Action(LoginToken)
  loginToken(ctx: StateContext<AuthStateModel>, action: LoginToken) {
    return this.authService.loginToken(action.payload.token).pipe(
      tap((result) => {
        ctx.patchState({
          access_token: result.access_token,
          refresh_token: result.access_token
        });
        // this.store.dispatch(new GetUser())
      })
    );
  }

  @Action(RegisterProviderToken)
  registerProviderAction(ctx: StateContext<AuthStateModel>, action: RegisterProviderToken) {
    return this.authService.callBack(action.payload.provider, action.payload.code, action.payload.phone).pipe(
      tap((result) => {
        ctx.patchState({
          access_token: result.access_token,
          refresh_token: result.access_token
        });
      })
    );
  }

  @Action(LoginProviderToken)
  loginProviderAction(ctx: StateContext<AuthStateModel>, action: LoginProviderToken) {
    return this.authService.checkIfHasAccount(action.payload.provider, action.payload.code).pipe(
      tap((result) => {
        ctx.patchState({
          access_token: result.access_token,
          refresh_token: result.access_token
        });
      })
    );
  }

  @Action(Register)
  register(ctx: StateContext<AuthStateModel>, action: Register) {
    return this.authService.register(action.payload).pipe(
      tap((result) => {
        ctx.patchState({
          access_token: result.access_token,
          refresh_token: result.access_token
        });
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    // ctx.patchState({
    //   access_token: null,
    //   refresh_token: null
    // });

    return this.authService.logout().pipe(
      tap((result) => {
        ctx.patchState({
          access_token: null,
          refresh_token: null
        });
        this.store.dispatch(new LogoutSubscriber());
      })
    );
  }

  @Action(Recover)
  recover(ctx: StateContext<AuthStateModel>, action: Recover) {
    return this.authService.recover(action.payload.email).pipe(
      tap((result) => {
        // ctx.patchState({
        //   access_token: result.access_token,
        //   refresh_token: result.access_token
        // });
      })
    );
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<AuthStateModel>, action: Recover) {
    return this.authService.resetPassword(action.payload).pipe(
      tap((result) => {
        // ctx.patchState({
        //   access_token: result.access_token,
        //   refresh_token: result.access_token
        // });
      })
    );
  }

}
