<ng-container *ngIf="notification.target === 'message' && notification.content.proposition.job_offer">
  <div class="card-body pointer" [routerLink]="notification.url" [queryParams]="{ messages: 'true'}">
    <div class="d-flex gap-3">
      <div>
        <ng-container *ngIf="notification.content.sender.profile_type === 'C'">
          <app-avatar [subscriber]="notification.content.sender" size="sm" target="notification"></app-avatar>
        </ng-container>
        <ng-container *ngIf="notification.content.sender.profile_type === 'E'">
          <app-avatar [eProfile]="notification.content.proposition.e_profile" size="sm" target="notification"></app-avatar>
        </ng-container>
      </div>
      <div class="d-flex flex-column gap-2">
        <div class="color-tertiary">
          <span class="bold">{{notification.content.sender.fullname}}</span> vous a envoyé un nouveau message
        </div>
        <div class="text-muted font-size-12">
          {{notification.created_at | date : 'short'}}
        </div>
        <div class="color-tertiary">
          {{notification.content.proposition.job_offer.title}}
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="notification.target === 'alert'">
  <div class="card-body pointer" [routerLink]="notification.url">
    <div class="d-flex gap-3">
      <div>
        <app-avatar [eProfile]="notification.content.e_profile" size="sm" target="notification"></app-avatar>
      </div>
      <div class="d-flex flex-column gap-2">
        <div class="color-tertiary bold">
          Un nouveau candidat correspond à votre Alerte
        </div>
        <div class="text-muted font-size-12">
          {{notification.created_at | date : 'short'}}
        </div>
        <div class="color-tertiary">
          <ng-container *ngIf="notification.content.alert">
            {{notification.content.alert.title}}
          </ng-container>
          <ng-container *ngIf="!notification.content.alert">
            Alerte Supprimée
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="notification.target === 'proposition' && notification.content.proposition.job_offer">
  <ng-container *ngIf="notification.sub_target === 'proposition'">
    <div class="card-body pointer" [routerLink]="notification.url" [queryParams]="{ messages: 'true'}">
      <div class="d-flex gap-3">
        <div>
          <app-avatar [eProfile]="notification.content.e_profile" size="sm" target="notification"></app-avatar>
        </div>
        <div class="d-flex flex-column gap-2">
          <div class="color-tertiary" *ngIf="notification.content.proposition.business === 1">
            <span class="bold">{{notification.content.e_profile.enterprise}}</span> souhaite entrer en contact avec vous
          </div>
          <div class="color-tertiary" *ngIf="notification.content.proposition.business === 2">
            <span class="bold">{{notification.content.e_profile.enterprise}}</span> souhaite rentrer en contact avec vous
          </div>
          <div class="text-muted font-size-12">
            {{notification.created_at | date : 'short'}}
          </div>
          <div class="color-tertiary">
            {{notification.content.proposition.job_offer.title}}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="notification.sub_target === 'accept'">
    <div class="card-body pointer" [routerLink]="notification.url" [queryParams]="{ messages: 'true'}">
      <div class="d-flex gap-3">
        <div>
          <app-avatar [subscriber]="notification.content.c_profile.subscriber" size="sm" target="notification"></app-avatar>
        </div>
        <div class="d-flex flex-column gap-2">
          <div class="color-tertiary">
            <span class="bold">{{notification.content.c_profile.subscriber.fullname}}</span> à accepté votre demande de mise en relation
          </div>
          <div class="text-muted font-size-12">
            {{notification.created_at | date : 'short'}}
          </div>
          <div class="color-tertiary">
            {{notification.content.proposition.job_offer.title}}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="notification.sub_target === 'refuse'">
    <div class="card-body pointer" [routerLink]="notification.url" [queryParams]="{ messages: 'true'}">
      <div class="d-flex gap-3">
        <div class="d-flex flex-column gap-2">
          <div class="color-tertiary">
            Un candidat à refusé votre demande de mise en relation
          </div>
          <div class="text-muted font-size-12">
            {{notification.created_at | date : 'short'}}
          </div>
          <div class="color-tertiary">
            {{notification.content.proposition.job_offer.title}}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="notification.sub_target === 'opinions'">
  <div class="card-body pointer" [routerLink]="notification.url" [queryParams]="{ notes: 'true'}">
    <div class="d-flex gap-3">
      <div>
        <app-avatar [subscriber]="notification.content.collaborator" size="sm" target="notification"></app-avatar>
      </div>
      <div class="d-flex flex-column gap-2">
        <div class="color-tertiary">
          <span class="bold">{{notification.content.collaborator.fullname}}</span> à ajouté un commentaire sur un candidat
        </div>
        <div class="text-muted font-size-12">
          {{notification.created_at | date : 'short'}}
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="notification.sub_target === 'share'">
  <div class="card-body pointer" [routerLink]="notification.url">
    <div class="d-flex gap-3">
      <div>
        <app-avatar [subscriber]="notification.content.collaborator" size="sm" target="notification"></app-avatar>
      </div>
      <div class="d-flex flex-column gap-2">
        <div class="color-tertiary">
          <span class="bold">{{notification.content.collaborator.fullname}}</span> vous a partagé un candidat
        </div>
        <div class="text-muted font-size-12">
          {{notification.created_at | date : 'short'}}
        </div>
      </div>
    </div>
  </div>
</ng-container>
