import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, tap, throwError} from "rxjs";
import {TokenService} from "../../services/token.service";
import {catchError} from "rxjs/operators";
import {environment} from "../../../environments/environment";


const API_URL = environment.API_URL;
const PATH = '/subscriber'

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: 'Basic ' + btoa(environment.OAUTH_CLIENT + ':' + environment.OAUTH_SECRET)
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl = '';

  constructor(private http: HttpClient, private tokenService: TokenService) {
  }

  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
    } else {
    }
    return throwError(
    'Something bad happened; please try again later.');
  }

  login(email :string, password: string): Observable<any> {
    const body = new HttpParams()
    .set('username', email)
    .set('password', password)
    .set('grant_type', 'password');

    return this.http.post<any>(environment.API_OAUTH_URL, body, HTTP_OPTIONS)
  }

  loginToken(token :string): Observable<any> {
    const body = new HttpParams()
    .set('token', token)

    return this.http.post<any>(API_URL + PATH + '/token', body, HTTP_OPTIONS)
  }

  logout(): Observable<any> {
    return this.http.get<any>(API_URL + PATH + '/logout')
  }

  register(body: any): Observable<any> {
    return this.http.post<any>(API_URL + PATH, body)
  }

  checkIfHasAccount(provider: string | null, code: string | null): Observable<any> {
    return this.http.get<any>(API_URL + `/auth/check?provider=${provider}&code=${code}`)
  }

  redirectProvider(provider: string): Observable<any> {
    return this.http.get<any>(API_URL + `/auth/redirect/${provider}`)
  }

  callBack(provider: string | null, code: string | null, phone: string): Observable<any> {
    return this.http.post<any>(API_URL + `/auth/callback`, {provider, code, phone})
  }

  refreshToken(refreshData: any): Observable<any> {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set('refresh_token', refreshData.refresh_token)
      .set('grant_type', 'refresh_token');
    return this.http.post<any>(API_URL + 'oauth/token', body, HTTP_OPTIONS)
      .pipe(
        tap(res => {
          this.tokenService.saveToken(res.access_token);
          this.tokenService.saveRefreshToken(res.refresh_token);
        }),
        catchError(AuthService.handleError)
      );
  }

  recover(email :string): Observable<any> {
    return this.http.post<any>(API_URL + PATH + '/forgotPassword',{email})
  }

  resetPassword(body: any): Observable<any> {
    return this.http.post<any>(API_URL + PATH + '/resetPassword',body)
  }
}
