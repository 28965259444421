import {Injectable, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Subject, takeUntil, throwError} from 'rxjs';
import {TokenService} from './token.service';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from '../auth/data-access/auth.service';
import {AuthState} from "../state/auth.state";
import { Store } from '@ngxs/store';
import {environment} from "../../environments/environment";
import {ToastrService} from "ngx-toastr";


@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  private access_token: string | null;
  private refresh_token: string | null;

  ngUnsubscribe = new Subject<void>();

  constructor(private router: Router, private tokenService: TokenService,
              private authService: AuthService, private store: Store, private toast: ToastrService) {

    this.store.select(AuthState).pipe(takeUntil(this.ngUnsubscribe)).subscribe(authState => {
      this.access_token = authState.access_token
      this.refresh_token = authState.refresh_token
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {

    if (request.url.includes(environment.API_URL) || request.url.includes(environment.API_OAUTH_URL)) {
      if (this.access_token === null) {
        this.access_token = this.store.selectSnapshot(AuthState.token)
      }

      if (this.access_token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + this.access_token
          }
        });
      }

      if (!request.headers.has('Content-Type')) {
        let contentType = request.detectContentTypeHeader()
        if (contentType) {
          request = request.clone({
            setHeaders: {
              'Content-Type': contentType
            }
          });
        }
      }

      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
    }

    request = request.clone({
      headers: request.headers
    });

    return next.handle(request).pipe(
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      switch (error.status) {
        case 400:
          this.toast.error(`${error.error.message}`, 'Un problème est survenu')
          this.router.navigate(['..'], { replaceUrl: true, skipLocationChange: false });
          break
        case 401:
          if (error.error.error === 'invalid_token') {
            this.authService.refreshToken({refresh_token: this.refresh_token}).pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              location.reload();
            });
          } else if (!request.url.includes('/logout')) {
            this.router.navigate(['login']).then(_ => console.log('redirect to login'));
          }
          break
        case 403:
          this.toast.error('Cette ressource ne vous est pas attribuée', 'Accès interdit')
          this.router.navigate(['..'], { replaceUrl: true, skipLocationChange: false });
          break
        case 404:
          this.toast.info('Cette ressource est introuvable', 'Ressource introuvable')
          this.router.navigate(['..'], { replaceUrl: true, skipLocationChange: false });
          break
        case 500:
          this.toast.error('Veuillez recommencer ultérieurement', 'Un problème est survenu')
          break
      }
      return throwError(() => error)
    }));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
